import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import Input from '../../../ui/input/index.tsx'

import Button from '../../../ui/button/index.tsx'
import api from '../../../services/app/client-server/axios-config.service'

const EditPassword = () => {
  const navigate = useNavigate()
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const token = useSelector(state => state.authReducer.authData.token)
  const [errors, setErrors] = useState({})
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const currentPassportChange = param => {
    setCurrentPassword(param)
    setErrors(prevErrors => ({ ...prevErrors, current_password: '' }))
  }

  const newPassportChange = param => {
    setNewPassword(param)
    setErrors(prevErrors => ({ ...prevErrors, password: '' }))
  }

  const confirmPassportChange = param => {
    setConfirmPassword(param)
    setErrors(prevErrors => ({ ...prevErrors, password_confirmation: '' }))
  }

  const handlePasswordChange = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/password`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }

      const requestData = {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmPassword,
      }

      await api.post(apiUrl, requestData, { headers })

      navigate('/')
    } catch (error) {
      setErrors(error.response.data.errors)
    }
  }

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <div className='edit-password-box'>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3'>Сменить пароль</Typography>
          <div className='close-icon' onClick={() => navigate('/metriva/protocols')}>
            <i className='fas fa-times user-icon-exit' />
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Input
            label={'Текущий пароль'}
            type={'password'}
            placeholder={'***********'}
            value={currentPassword}
            actions={{
              change: currentPassportChange,
            }}
            error={!!errors.current_password}
          />
          {errors.current_password && <div className='error'>{errors.current_password[0]}</div>}
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Input
            label={'Новый пароль'}
            type={'password'}
            placeholder={'***********'}
            value={newPassword}
            actions={{
              change: newPassportChange,
            }}
            error={!!errors.password}
          />
          {errors.password && <div className='error'>{errors.password[0]}</div>}
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Input
            label={'Подтвердить пароль'}
            type={'password'}
            placeholder={'***********'}
            value={confirmPassword}
            actions={{
              change: confirmPassportChange,
            }}
            error={!!errors.password_confirmation}
          />
          {errors.password_confirmation && (
            <div className='error'>{errors.password_confirmation[0]}</div>
          )}
        </div>

        <Button onClick={handlePasswordChange} label='Изменить пароль' color='primary' fullWidth />
      </div>
    </main>
  )
}

export default EditPassword
