import React, { useEffect, useState } from 'react'
import NavBarMobile from '../components/NavBarMobile'
import NavBarMobileHeader from '../components/NavBarMobileHeader.tsx'
import Feedback from '../components/LocalElements/Feedback/Feedback'

import HelloScreen from './Auth/HelloScreen'

import RoutesComponent from '../../routes/RouteComponent'
import PositionedSnackbar from '../components/LocalElements/Snackbar/Snackbar'
import ModalComponent from '../components/LocalElements/Modal/Modal'
import FileViewerComponent from '../components/LocalElements/Modal/FileViewer'
import ImageViewerComponent from '../components/LocalElements/Modal/ImageViewer'

import ErrorBoundary from '../../services/app/processes/error-boundary.service'

const Home = () => {
  const [readyData, setReadyData] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setReadyData(true), 2200)
    return () => clearTimeout(timer)
  }, [])

  return (
    <React.Fragment>
      {readyData ? (
        <React.Fragment>
          <div className='home'>
            <div className='main_box' style={{ position: 'relative' }}>
              <Feedback />
              <NavBarMobileHeader />
              <ErrorBoundary key={new Date().getTime()}>
                <RoutesComponent />
              </ErrorBoundary>
              <PositionedSnackbar />
              <ModalComponent />
              <FileViewerComponent />
              <ImageViewerComponent />
              <NavBarMobile />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <HelloScreen />
      )}
    </React.Fragment>
  )
}

export default Home
