// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { setAuthToken, removeToken } from './store/slices/app/controlers/auth'

import Login from './app/pages/Auth/Auth'
import HomeDesktop from './app/pages/HomeLegacy.jsx'
import HomeMobile from './app/pages/HomeMobile.jsx'
import HomeToolpad from './app/pages/HomeToolpad.jsx'

import Updater from './app/pages/Updater'
import SocketConnector from './services/app/client-server/socket-connector.service.js'
import ProtectedRoute from './routes/ProtectedRoute'

import { LicenseInfo } from '@mui/x-data-grid-pro'
import HistoryListener from './services/app/processes/history-listener.service.js'
import useWindowDimensions from './app/hooks/useWindowDimensions.js'

import Cookies from 'js-cookie'
import './index.scss'
import './index.layout.scss'
import './index.mobile.scss'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { width } = useWindowDimensions()

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

  const [firstUpdate, setFirstUpdate] = useState(false)
  const reduxToken = useSelector(state => state.authReducer.authData.token)

  useEffect(() => {
    const TOKEN = Cookies.get('accessToken')

    TOKEN && dispatch(setAuthToken(TOKEN))
    !!!TOKEN && dispatch(removeToken())

    setFirstUpdate(true)

    // ----------------------------------------------------------------
    // navigate('/metriva/protocol')
    // ----------------------------------------------------------------
  }, [])

  useEffect(() => {
    const checkCookie = () => {
      const TOKEN = Cookies.get('accessToken')
      if (!TOKEN && reduxToken) {
        dispatch(removeToken())
        navigate('/')
      }
    }

    const intervalId = setInterval(checkCookie, 1000 * 60)

    return () => clearInterval(intervalId)
  }, [reduxToken, dispatch, navigate])

  return (
    <React.Fragment>
      <SocketConnector />
      <HistoryListener />
      <div className='App'>
        {firstUpdate && <Updater />}
        <Routes>
          <Route
            path='/'
            element={
              !!!reduxToken ? <Login /> : <Navigate replace={true} to={'/metriva/protocol'} />
            }
          />
          <Route
            path='/metriva/*'
            element={
              <ProtectedRoute>
                {width <= 1050 && <HomeMobile />}
                {width > 1050 && <HomeDesktop />}

                {/* {width > 1050 && <HomeToolpad />} */}
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </React.Fragment>
  )
}

export default App
