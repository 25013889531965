// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import R from '../../../services/app/client-server/request.service'

import WaterMi1592Edit from '../../components/Methodologies/water/Mi-1592-2015/desktop/Edit'

import { changeCase } from '../../../store/slices/app/views/protocols'

const Edit = () => {
  const { protocol_id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getProtocol(protocol_id)

      dispatch(
        changeCase({
          value: 'pointId',
          label: data.data?.point?.id,
        }),
      )
    })()
  }, [])

  const navigateToProtocol = () => {
    navigate('/metriva/protocols')
  }

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      <div className='path_to_back' onClick={navigateToProtocol}>
        <i
          className='fas fa-arrow-left icon_back'
          style={{ width: '30px', height: '30px', marginRight: '6px' }}
        />
        <p>Назад к списку протоколов</p>
      </div>

      {(pointId === 1 || pointId === 3) && <WaterMi1592Edit />}
    </main>
  )
}

export default Edit
