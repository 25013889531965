// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import Input from '../../../app/components/LocalElements/Input/InputMUI'
import { Button } from '@mui/material'
import ErrorBoundaryHOCComponent from '../../../app/hocs/ErrorBoundary.HOC'

class ErrorBoundaryClassComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: '',
      errorInfo: '',

      supportMessage: '',
    }
  }

  async componentDidCatch(error, errorInfo) {
    this.setState({ error: `${error.name}: ${error.message}` })
    this.setState({ errorInfo: JSON.stringify(errorInfo) })
    this.setState({ hasError: !this.state.hasError })

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const BOT_ENDPOINT = 'https://api.telegram.org/bot'
    const BOT_TOKEN_RESERVE = '7312163888:AAH9OdST31q8Z4Bx9U1jbcREmg_XdkTHCTk'
    const BOT_TOKEN = '8056611982:AAENouagpTUPRLfE5ex9hWLdFRoHad1u38E'

    const raw = JSON.stringify({
      message: `[ ${document.location.href} ] ${error.name}: ${error.message}`,
    })
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    // ----------------------------------------------------------------
    // временное решение, не работает с докером, тк при рестарте
    // контейнера весь файл с логами перезаписывается
    // создано как мвп работоспобное по сохранению логов
    // ----------------------------------------------------------------

    fetch(`${process.env.REACT_APP_SUPPORT_URL}/data`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.error(error))

    const botMessage = `
      <b>Произошел краш приложения</b>\n<u>${document.location.href}</u>\n\nОшибка ${
        error.name
      } - ${error.message}\n\n<pre>${JSON.stringify(errorInfo)}</pre>
    `
    // await fetch(`${BOT_ENDPOINT}${BOT_TOKEN}/sendMessage`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json, text/plain, */*',
    //   },
    //   body: JSON.stringify({
    //     chat_id: '-4500368746',
    //     text: botMessage,
    //     parse_mode: 'HTML',
    //   }),
    // })

    false && console.log(errorInfo)
  }

  returnErrorInfo(errorInfo) {
    return errorInfo
  }

  reloadApp = () => {
    this.props.reloadApp()
  }

  render() {
    const { error, errorInfo } = this.state

    if (!!error) {
      return (
        <main className='main'>
          <div className='edit-password-box'>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '14px',
              }}
            >
              <h2 style={{ width: '100%', textAlign: 'center' }}>Произошел сбой приложения</h2>
            </div>

            <span style={{ marginBottom: '13px', backgroundColor: '#FF6E77' }}>
              {error ? error : 'Наименование ошибки'}
            </span>

            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '14px',
              }}
            >
              <h3 style={{ width: '100%', textAlign: 'center' }}>
                Нам уже пришло оповещение о возникшем сбое, и в скором времени мы закроем данную
                уязвимость
              </h3>
            </div>
            {/* <span
              style={{
                marginBottom: '20px',
                width: '100%',
                lineHeight: '24px',
                textAlign: 'center',
              }}
            >
              <pre>
                <code>
                  {this.returnErrorInfo(errorInfo)
                    ? this.returnErrorInfo(errorInfo)
                    : 'Более подробное описание ошибки'}
                </code>
              </pre>
            </span> */}

            <div style={{ width: '100%', marginBottom: '0px' }}>
              <Input
                label={'Можете оставить комментарий - минимум 50 знаков'}
                type={'text'}
                notRequired
                placeholder={'Опишите в каких обстоятельствах произошла ошибка'}
                value={this.state.supportMessage}
                actions={{
                  change: param => this.setState({ supportMessage: param }),
                }}
              />
            </div>

            <div style={{ marginTop: '0px', width: '100%' }}>
              <Button
                variant='contained'
                disabled={this.state.supportMessage.length > 10 ? false : true}
                onClick={() => {
                  this.setState({ supportMessage: '' })
                }}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  margin: 0,
                  borderRadius: '12px',
                  width: '100%',
                }}
              >
                Оставить сообщение
              </Button>
            </div>
            <span
              style={{
                marginBottom: '13px',
                marginTop: '20px',
                letterSpacing: '1px',
                color: 'gray',
              }}
            >
              или
            </span>
            <div style={{ marginTop: '10px', width: '100%' }}>
              <Button
                variant='outlined'
                onClick={this.reloadApp}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  margin: 0,
                  borderRadius: '12px',
                  width: '100%',
                }}
              >
                Перезагрузить приложение
              </Button>
            </div>
          </div>
        </main>
      )
    } else {
      return <React.Fragment>{this.props.children}</React.Fragment>
    }
  }
}

const ErrorBoundary = ErrorBoundaryHOCComponent(ErrorBoundaryClassComponent)

export default ErrorBoundary
