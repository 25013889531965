// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
// /* eslint-disable array-callback-return */
// ----------------------------------------------------------------
// /* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState, useRef } from 'react'
import { ruRU } from '@mui/x-data-grid'
import { DataGridPro, GridOverlay, GridRowModes } from '@mui/x-data-grid-pro'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'

import CustomToolbar from './CustomToolbar'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

// ----------------------------------------------------------------
// Функция для расчёта ширины текста
// ----------------------------------------------------------------

const getTextWidth = (text, font) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')

  context.font = font
  const metrics = context.measureText(text)

  return metrics.width
}

// ----------------------------------------------------------------
// Функция для извлечения текста из React узла
// ----------------------------------------------------------------

const extractTextFromReactNode = node => {
  if (typeof node === 'string') {
    return node
  } else if (React.isValidElement(node)) {
    return React.Children.toArray(node.props.children).map(extractTextFromReactNode).join('')
  } else if (Array.isArray(node)) {
    return node.map(extractTextFromReactNode).join('')
  }
  return ''
}

// ----------------------------------------------------------------
// Функция для расчёта ширины и минимальной ширины колонки
// ----------------------------------------------------------------

const calculateColumnWidth = (column, rows, font) => {
  const headerText =
    typeof column.headerName === 'string'
      ? column.headerName
      : extractTextFromReactNode(column.headerName)
  const headerWidth = getTextWidth(headerText, font) + 66 // Дополнительные отступы

  let maxContentWidth = 0
  rows.forEach(row => {
    const value = row[column.field]
    const text = value ? value.toString() : ''
    const contentWidth = getTextWidth(text, font) + 66

    if (contentWidth > maxContentWidth) {
      maxContentWidth = contentWidth
    }
  })

  const maxWidth = Math.max(headerWidth, maxContentWidth)

  return {
    width: Math.min(maxWidth, 500), // Максимальная ширина
    minWidth: headerWidth, // Минимальная ширина равна ширине заголовка
  }
}

const Table = props => {
  const {
    colData,
    rowData,
    actions = {
      static: null,
      dinamic: null,
    },
    noCreating = false,
    rowCallback = () => {},
    rowSelectionCallback = () => {},
    addButtonCallback = () => {},
    searchParams = { query: null, builder: () => {} },
    hasCheckboxSelection,
    hideFooter,
    getRowHeight,
    initialState,
    rowModesModel,
    setRowModesModel,
    showToolbar = false,
    noRowsText = 'Информация отсутствует',
    filtersVariant = 'client',
    setIsDrawerOpenServer,
  } = props

  const customRuRU = {
    ...ruRU,
  }

  const locale = createTheme({
    ...createTheme(),
    ...customRuRU,
  })

  const { width } = useWindowDimensions()

  const [selectedRows, setSelectedRows] = useState([])
  const [filters, setFilters] = useState({})
  const [filteredRows, setFilteredRows] = useState(rowData)
  const [columns, setColumns] = useState([])

  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)

  // ----------------------------------------------------------------
  // Управление закрепленными колонками
  // ----------------------------------------------------------------

  const [pinnedColumns, setPinnedColumns] = useState({
    right: width > 1050 ? ['actions'] : [],
    left: [],
  })

  // ----------------------------------------------------------------
  // Обработка изменения размера контейнера
  // ----------------------------------------------------------------

  useEffect(() => {
    if (!containerRef.current) return

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width)
      }
    })

    resizeObserver.observe(containerRef.current)

    setContainerWidth(containerRef.current.offsetWidth)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  // ----------------------------------------------------------------
  // Обработка изменения выбранных строк
  // ----------------------------------------------------------------

  useEffect(() => {
    rowSelectionCallback(selectedRows)
  }, [selectedRows])

  // ----------------------------------------------------------------
  // Обработка фильтров
  // ----------------------------------------------------------------

  useEffect(() => {
    let filtered = rowData

    Object.keys(filters).forEach(field => {
      if (field !== 'query') {
        const value = filters[field]
        if (value) {
          const isRangeFilter = Array.isArray(value) && value.length === 2

          filtered = filtered.filter(row => {
            const cellValue = row[field]

            if (isRangeFilter) {
              const [min, max] = value
              return typeof cellValue === 'number' && cellValue >= min && cellValue <= max
            } else {
              const cellValueString = cellValue != null ? String(cellValue).toLowerCase() : ''
              const filterValueString = String(value).toLowerCase()

              return cellValueString.includes(filterValueString)
            }
          })
        }
      }
    })

    const queryValue = filters['query']

    if (queryValue) {
      filtered = filtered.filter(row => {
        return Object.values(row).some(
          field =>
            (typeof field === 'string' || typeof field === 'number') &&
            field?.toString().toLowerCase().includes(queryValue.toLowerCase()),
        )
      })
    }

    setFilteredRows(filtered)
  }, [filters, rowData])

  // ----------------------------------------------------------------
  // Настройка колонок
  // ----------------------------------------------------------------

  useEffect(() => {
    const font = "'Wix Madefor Display', sans-serif"

    const actionColumns = colData.filter(col => col.field === 'actions')
    const otherColumns = colData.filter(col => col.field !== 'actions')

    // ----------------------------------------------------------------
    // Вычисляем ширину и минимальную ширину для остальных колонок
    // ----------------------------------------------------------------

    const calculatedColumns = otherColumns.map(col => {
      const { width, minWidth } = calculateColumnWidth(col, rowData, `15px ${font}`)
      return {
        ...col,
        sortable: true, // Включаем сортировку (можно изменить по необходимости)
        filterable: col.field !== 'actions',
        width: width,
        minWidth: minWidth, // Устанавливаем минимальную ширину
        maxWidth: 440,
        flex: 0,
      }
    })

    // ----------------------------------------------------------------
    // Настройка колонки действий
    // ----------------------------------------------------------------

    actionColumns.forEach(col => {
      calculatedColumns.push({
        ...col,
        width: 170,
        minWidth: 170,
        maxWidth: 170,
        flex: 0,
        sortable: false,
        filterable: false,
        headerClassName: 'actions-header',
        cellClassName: 'actions-cell',
      })
    })

    if (containerWidth > 0) {
      const totalColumnsWidth = calculatedColumns.reduce((acc, col) => acc + (col.width || 0), 0)

      const availableWidth = containerWidth - 300

      if (totalColumnsWidth < availableWidth) {
        let widestColumn = calculatedColumns
          .filter(col => col.field !== 'actions')
          .reduce(
            (prev, current) => (prev.width > current.width ? prev : current),
            calculatedColumns[0],
          )

        const missingWidth = availableWidth - (totalColumnsWidth - widestColumn.width)

        const updatedColumns = calculatedColumns.map(col => {
          if (col.field === widestColumn.field) {
            return { ...col, width: col.width + missingWidth }
          }
          return col
        })

        setColumns(updatedColumns)
      } else {
        setColumns(calculatedColumns)
      }
    } else {
      setColumns(calculatedColumns)
    }
  }, [colData, rowData, containerWidth])

  // ----------------------------------------------------------------
  // Очистка фильтров при размонтировании компонента
  // ----------------------------------------------------------------

  useEffect(() => {
    return () => {
      setFilters({})
    }
  }, [])

  // ----------------------------------------------------------------
  // Обработка изменения закрепленных колонок
  // ----------------------------------------------------------------

  const handlePinnedColumnsChange = newPinned => {
    setPinnedColumns(newPinned)
  }

  // ----------------------------------------------------------------
  // Обработка обновления строки
  // ----------------------------------------------------------------

  const handleRowEditCommit = updatedRow => {
    const updatedRows = rowData.map(row =>
      row.id === updatedRow.id ? { ...row, ...updatedRow } : row,
    )
    if (props.onRowUpdate) {
      props.onRowUpdate(updatedRows)
    } else {
      return null
    }
  }

  // ----------------------------------------------------------------
  // Локализация кнопок "Show/Hide All" и "Reset" через MutationObserver
  // ----------------------------------------------------------------

  useEffect(() => {
    const observer = new MutationObserver(mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === 1) {
              const spans = node.querySelectorAll('span.MuiTypography-body1')
              spans.forEach(span => {
                if (span.textContent === 'Show/Hide All') {
                  span.textContent = 'Показать или скрыть все'
                }
              })

              const buttons = node.querySelectorAll('button.MuiButton-root')
              buttons.forEach(button => {
                if (button.textContent.trim() === 'Reset') {
                  button.textContent = 'Сбросить'
                  button.style.textTransform = 'none'
                }
              })

              const inputs = node.querySelectorAll('input.MuiInputBase-inputTypeSearch')
              inputs.forEach(input => {
                if (input.getAttribute('placeholder').trim() === 'Search') {
                  input.setAttribute('placeholder', 'Поиск по колонкам')
                }
              })
            }
          })
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <ThemeProvider theme={locale}>
      <Box ref={containerRef} sx={{ minHeight: 155, width: '100%' }}>
        <DataGridPro
          slots={{
            toolbar: showToolbar === false ? null : CustomToolbar,
            noRowsOverlay: () => (
              <GridOverlay>
                <Box
                  sx={{
                    mt: 1,
                    color: '#132532',
                    fontSize: '18.8px',
                    textAlign: 'center',
                    fontWeight: '400',
                    letterSpacing: '0.4px',
                  }}
                >
                  {noRowsText}
                </Box>
              </GridOverlay>
            ),
          }}
          slotProps={{
            toolbar: {
              rowData: rowData,
              columns: colData,
              filters: filters,
              actions: actions,

              noCreating: noCreating,
              setFilters: setFilters,
              searchParams: searchParams,
              filtersVariant: filtersVariant,
              selectedRowCount: selectedRows.length,

              addButtonCallback: addButtonCallback,
              setIsDrawerOpenServer: setIsDrawerOpenServer,
            },
          }}
          rows={filteredRows}
          columns={columns}
          initialState={initialState}
          pinnedColumns={pinnedColumns}
          onPinnedColumnsChange={handlePinnedColumnsChange}
          pagination
          pageSizeOptions={[10, 20, 30, 40, 50]}
          checkboxSelection={hasCheckboxSelection}
          rowSelectionModel={selectedRows}
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          onRowSelectionModelChange={setSelectedRows}
          processRowUpdate={newRow => {
            handleRowEditCommit(newRow)
            return newRow
          }}
          disableColumnMenu={false}
          onRowClick={rowCallback && rowCallback}
          hideFooter={hideFooter ? hideFooter : false}
          getRowHeight={
            rowModesModel
              ? params => (rowModesModel[params.id]?.mode === GridRowModes.Edit ? 60 : '')
              : getRowHeight
          }
          sx={{
            width: '100%',
            borderRadius: '12px',
            padding: '10px',
            color: '#132532',
            marginTop: '12px',
            backgroundColor: '#ffffff',
            border: '1px solid #d7ddea',
            overflow: 'auto',
            display: 'flex',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: '20px',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-cell:hover': {
              cursor: 'default',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              lineHeight: '22px',
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#F8F9FC',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell--editing:focus-within': {
              outline: 'none !important',
            },
            '& .MuiDataGrid-cell--editing': {
              borderRadius: '0px',
              marginRight: '0px',
              boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.1) !important',
            },
            '& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon': {
              visibility: 'hidden',
              transition: 'visibility 0.2s',
              display: 'none',
            },
            '& .MuiDataGrid-columnHeader:hover .MuiDataGrid-sortIcon': {
              visibility: 'visible',
            },
          }}
        />
      </Box>
    </ThemeProvider>
  )
}

export default Table
